export const gradientVariants = {
    initial: {
      opacity: 0,
      scale:0,
    },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 2,
      },
    },
  };