
import { motion, useScroll, useTransform } from "framer-motion";
import "./HeadingSection.scss";
import { useMemo, useRef } from "react";
import useScreenSize from "App/Hooks/ScreenSize";

const HeadingSection = () => {
  const textRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: textRef,
    offset: ["start start", "end start"],
  });
  const screenSize = useScreenSize()?.width;
  const textLeftXScrollWeb = useTransform(scrollYProgress, [1, 0], [-50, 0]);
  const textRightXScrollWeb = useTransform(scrollYProgress, [1, 0], [50, 0]);

  const textLeftXScrollMobile = useTransform(scrollYProgress, [1, 0], [-20, 0]);
  const textRightXScrollMobile = useTransform(scrollYProgress, [1, 0], [30, 0]);
  const textLeftXScroll = useMemo(() => {
    return screenSize > 768 ? textLeftXScrollWeb : textLeftXScrollMobile;
  }, [screenSize, textLeftXScrollWeb, textLeftXScrollMobile]);
  const textRightXScroll = useMemo(() => {
    return screenSize > 768 ? textRightXScrollWeb : textRightXScrollMobile;
  }, [screenSize, textRightXScrollWeb, textRightXScrollMobile]);
  return (
    <div className="creators-hero-heading overflow-hidden" ref={textRef}>
      <div className="text-wrapper">
        <motion.h1 style={{ x: textLeftXScroll }}>Cur8 for</motion.h1>
        <motion.h1 style={{ x: textRightXScroll }}>Creators</motion.h1>
      </div>
    </div>
  );
};
export default HeadingSection;
