import "./HeadingAndImageBlock.scss";
import Button from "App/Components/Button";
import { useMemo, useRef } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import { OddOrEven } from "App/Helper/utilities";
import useScreenSize from "App/Hooks/ScreenSize";

const HeadingAndImageBlock = ({ data, index }) => {
  const blockRef = useRef(null);
  const screenSize = useScreenSize()?.width;
  const { scrollYProgress } = useScroll({
    target: blockRef,
    offset: ["start end", "end start"],
  });
  const blockXOddScrollWeb = useTransform(
    scrollYProgress,
    [0, 0.4, 1],
    [0, 0, 100]
  );
  const blockXEvenScrollWeb = useTransform(
    scrollYProgress,
    [0, 0.4, 1],
    [0, 0, -100]
  );
  const buttonOddYScrollWeb = useTransform(
    scrollYProgress,
    [1, 0.5, 0],
    [80, 0, 0]
  );
  const buttonEvenYScrollWeb = useTransform(
    scrollYProgress,
    [1, 0.5, 0],
    [-80, 0, 0]
  );


  const blockXOddScrollMobile = useTransform(
    scrollYProgress,
    [0, 0.4, 1],
    [0, 0, 0]
  );
  const blockXEvenScrollMobile = useTransform(
    scrollYProgress,
    [0, 0.4, 1],
    [0, 0, 0]
  );
  const buttonOddYScrollMobile = useTransform(
    scrollYProgress,
    [1, 0.5, 0],
    [0, 0, 0]
  );
  const buttonEvenYScrollMobile = useTransform(
    scrollYProgress,
    [1, 0.5, 0],
    [0, 0, 0]
  );
  const blockXOddScroll = useMemo(() => {
    return screenSize > 768 ? blockXOddScrollWeb : blockXOddScrollMobile;
  }, [screenSize, blockXOddScrollWeb, blockXOddScrollMobile]);

  const blockXEvenScroll = useMemo(() => {
    return screenSize > 768 ? blockXEvenScrollWeb : blockXEvenScrollMobile;
  }, [screenSize, blockXEvenScrollWeb, blockXEvenScrollMobile]);

  const buttonOddYScroll = useMemo(() => {
    return screenSize > 768 ? buttonOddYScrollWeb : buttonOddYScrollMobile;
  }, [screenSize, buttonOddYScrollWeb, buttonOddYScrollMobile]);
  
  const buttonEvenYScroll = useMemo(() => {
    return screenSize > 768 ? buttonEvenYScrollWeb : buttonEvenYScrollMobile;
  }, [screenSize, buttonEvenYScrollWeb, buttonEvenYScrollMobile]);
  return (
    <motion.div
      ref={blockRef}
      style={{
        x: OddOrEven(index) === "odd" ? blockXOddScroll : blockXEvenScroll,
      }}
      className={`heading-and-image-block relative flex flex-col`}
    >
      <h1>{data?.heading}</h1>
      <img className="image-content" src={data?.image} alt="" />
      <motion.a href={`${process.env.REACT_APP_USER_URL}${data?.link}`} target="_blank" rel="noreferrer" 
        style={{
          y: OddOrEven(index) === "odd" ? buttonOddYScroll : buttonEvenYScroll,
        }}
        className={`link-btn`}
      >
        {data?.linkText}
      </motion.a>
    </motion.div>
  );
};

export default HeadingAndImageBlock;
