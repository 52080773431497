import  './Button.scss'
import {motion} from "framer-motion"
const Button = props => {
    return (
        <motion.button
            style={props?.style||{}}
            className={`btn ${props.className}`}
            type={props?.type||"submit"}
            onClick={props.OnClick}
            disabled={props.disabled}
            {...props}
        >
            {props.children}
        </motion.button>

    )
}

export default Button






