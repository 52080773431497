import { motion, useScroll, useTransform } from "framer-motion";
import "../../Carousel.scss";
import { useRef } from "react";
import useScreenSize from "App/Hooks/ScreenSize";
import { OddOrEven } from "App/Helper/utilities";

const ImageBlock = ({
  slide,
  index,
  oddWebScrollVariant = { offset: [1, 0], value: [0, 0] },
  evenWebScrollVariant = { offset: [1, 0], value: [0, 0] },
  oddMobScrollVariant = { offset: [1, 0], value: [0, 0] },
  evenMobScrollVariant = { offset: [1, 0], value: [0, 0] },
}) => {
  const boxRef = useRef(null);
  const sreenSize = useScreenSize().width;
  const { scrollYProgress } = useScroll({
    target: boxRef,
    offset: ["start end", "end start"],
  });
  const oddWebXScroll = useTransform(
    scrollYProgress,
    oddWebScrollVariant?.offset,
    oddWebScrollVariant?.value
  );
  const evenWebXScroll = useTransform(
    scrollYProgress,
    evenWebScrollVariant?.offset,
    evenWebScrollVariant?.value
  );
  const oddMobXScroll = useTransform(
    scrollYProgress,
    oddMobScrollVariant?.offset,
    oddMobScrollVariant?.value
  );
  const evenMobXScroll = useTransform(
    scrollYProgress,
    evenMobScrollVariant?.offset,
    evenMobScrollVariant?.value
  );

  const oddBoxXScroll = sreenSize > 768 ? oddWebXScroll : oddMobXScroll;

  const evenBoxXScroll = sreenSize > 768 ? evenWebXScroll : evenMobXScroll;
  return (
    <>
      <motion.div
        ref={boxRef}
        style={{
          x: OddOrEven(index + 1) === "odd" ? oddBoxXScroll : evenBoxXScroll,
        }}
        key={slide?.id}
        className="slide-img slider"
      >
        <motion.img src={slide?.src} alt="" loading="lazy" />
      </motion.div>
    </>
  );
};

export default ImageBlock;
