import ReactPlayer from "react-player";

const Player = ({
  controls,
  playVideoHandler,
  isVideoPlaying,
  pauseVideoHanlder,
  playerRef,
  url,
}) => {
  return (
    <ReactPlayer
      controls={controls}
      onPlay={playVideoHandler}
      fullscreen={isVideoPlaying}
      onPause={pauseVideoHanlder}
      playing={isVideoPlaying}
      ref={playerRef}
      width={"100%"}
      height={"100%"}
      url={url}
    />
  );
};

export default Player;
