export const scrollToTop = (x = 0, y = 0) => {
  window.scrollTo(x, y);
};

export const OddOrEven = (num) => {
  if (num % 2 === 0) {
    return "even";
  }
  return "odd";
};

export const fullScreenHandler = (reference) => {
  if (reference) {
    if (reference.requestFullscreen) {
      reference.requestFullscreen().catch((error) => {
        console.error("Failed to enter fullscreen:", error);
      });
    } else if (reference.mozRequestFullScreen) {
      /* Firefox */
      reference.mozRequestFullScreen().catch((error) => {
        console.error("Failed to enter fullscreen:", error);
      });
    } else if (reference.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      reference.webkitRequestFullscreen().catch((error) => {
        console.error("Failed to enter fullscreen:", error);
      });
    } else if (reference.msRequestFullscreen) {
      /* IE/Edge */
      reference.msRequestFullscreen().catch((error) => {
        console.error("Failed to enter fullscreen:", error);
      });
    }
  }
};

export const exitFullscreen = () => {
  if (document?.exitFullscreen) {
    document.exitFullscreen().catch((error) => {
      console.error("Failed to enter normalscreen:", error);
    });
  } else if (document?.mozCancelFullScreen) {
    /* Firefox */
    document.mozCancelFullScreen().catch((error) => {
      console.error("Failed to enter normalscreen:", error);
    });
  } else if (document?.webkitExitFullscreen) {
    /* Chrome, Safari and Opera */
    document.webkitExitFullscreen().catch((error) => {
      console.error("Failed to enter normalscreen:", error);
    });
  } else if (document?.msExitFullscreen) {
    /* IE/Edge */
    document.msExitFullscreen().catch((error) => {
      console.error("Failed to enter normalscreen:", error);
    });
  }
};
