import HorizontalScrollSection from "App/Components/HorizontalScroll";
import slideOne from "Assets/Images/collectors/collectors-slider-1.png";
import slideTwo from "Assets/Images/collectors/collectors-slider-2.png";
import leftRedGradient from "Assets/Images/collectors/slider-left-red.webp";
import leftPurpleGradient from "Assets/Images/collectors/slider-left-purple.webp";
import rightPurpleGradient from "Assets/Images/collectors/slider-right-purple.webp";
import { motion } from "framer-motion";
import "./Carousel.scss";
import { leftGradientVariants, rightGradientVariants } from "./motion";
import ImageBlock from "./Components/ImageBlock";

const Carousel = () => {
  const slider = [
    { id: 1, src: slideOne },
    { id: 2, src: slideTwo },
  ];
  let TextBlocks = (
    <div className="text-wrapper slider">
      <h4>Discover, buy, sell, curate and share without limits.</h4>
    </div>
  );
  return (
    <div className="collectors-carousel hero-section-carousel relative">
      <motion.img
        className="absolute top-[-31vh] left-0 slider-floating-image"
        src={leftRedGradient}
        variants={leftGradientVariants}
        initial="initial"
        whileInView="visible"
        alt=""
      />
      <motion.img
        className="absolute top-0 left-0 slider-floating-image"
        src={leftPurpleGradient}
        variants={leftGradientVariants}
        initial="initial"
        whileInView="visible"
        alt=""
      />
      <HorizontalScrollSection className={"md:block hidden h-[100dvw]"}>
        <div className="flex gap-4">
          {TextBlocks}
          {slider?.map((slide, index) => (
            <ImageBlock slide={slide} index={index} />
          ))}
        </div>
      </HorizontalScrollSection>
      <div className="md:hidden flex flex-col overflow-hidden">
        {TextBlocks}
        <div className="mobile-image-block">
          {slider?.map((slide, index) => (
            <ImageBlock
              oddMobScrollVariant={{
                offset: [1, 0.1, 0],
                value: [0, 0, -30],
              }}
              evenMobScrollVariant={{
                offset: [1, 0.1, 0],
                value: [0, 0, 30],
              }}
              slide={slide}
              index={index}
            />
          ))}
        </div>
      </div>
      <motion.img
        className="absolute top-[-50vh] right-0 slider-floating-image"
        src={rightPurpleGradient}
        variants={rightGradientVariants}
        initial="initial"
        whileInView="visible"
        alt=""
      />
    </div>
  );
};
export default Carousel;
