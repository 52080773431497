import "./AppItemsList.scss";
import literatureBg from "Assets/Images/about/category/literature.png";
import literatureIcon from "Assets/Images/about/category/literature-icon.svg";
import listenBg from "Assets/Images/about/category/listen.png";
import listenIcon from "Assets/Images/about/category/listen-icon.svg";
import displayBg from "Assets/Images/about/category/display.png";
import displayIcon from "Assets/Images/about/category/display-icon.svg";
import sportsBg from "Assets/Images/about/category/sports.png";
import sportsIcon from "Assets/Images/about/category/sports-icon.svg";
import collectionBg from "Assets/Images/about/category/collection.png";
import collectionIcon from "Assets/Images/about/category/collection-icon.svg";
import collectiblesBg from "Assets/Images/about/category/collectibles.png";
import collectiblesIcon from "Assets/Images/about/category/collectibles-icon.svg";
import eventsBg from "Assets/Images/about/category/events.png";
import eventsIcon from "Assets/Images/about/category/events-icon.svg";
import booksBg from "Assets/Images/about/category/books.png";
import booksIcon from "Assets/Images/about/category/books-icon.svg";
import artBg from "Assets/Images/about/category/art.png";
import artIcon from "Assets/Images/about/category/art-icon.svg";
import galleriesBg from "Assets/Images/about/category/galleries.png";
import galleriesIcon from "Assets/Images/about/category/galleries-icon.svg";
import musicBg from "Assets/Images/about/category/music.png";
import musicIcon from "Assets/Images/about/category/music-icon.svg";
import moviesBg from "Assets/Images/about/category/movies.png";
import moviesIcon from "Assets/Images/about/category/movies-icon.svg";
import museumsBg from "Assets/Images/about/category/museums.png";
import museumsIcon from "Assets/Images/about/category/museums-icon.svg";
import photographyBg from "Assets/Images/about/category/photography.png";
import photographyIcon from "Assets/Images/about/category/photography-icon.svg";
import rightRedGradient from "Assets/Images/about/about-hero-right-red.png";
import rightPurple1Gradient from "Assets/Images/about/about-hero-right-purple-1.png";
import rightPurple2Gradient from "Assets/Images/about/about-hero-right-purple-2.png";
import leftPurpleGradient from "Assets/Images/about/about-hero-left-purple.png";
import blueSm from "Assets/Images/about/particles/particle-glass-blue-medium-sm.png";
import redSm from "Assets/Images/about/particles/particle-glass-red-sm.png";
import purpleSm from "Assets/Images/about/particles/particle-glass-purple-sm.png";
import redMd from "Assets/Images/about/particles/particle-glass-red-md.png";
import Cards from "./Components/Cards";
import Particles from "App/Components/Particles";
import { useRef } from "react";
import { useScroll, useTransform } from "framer-motion";

const AppItemsList = () => {
  const containerRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["start end", "end start"],
  });
  const particleYscroll = useTransform(
    scrollYProgress,
    [0,  1],
    ["0%","150%"]
  );
  return (
    <div className="app-items-list-section relative">
      <img
        className="gradient absolute top-[-30dvw] right-0 sm:inline-block hidden"
        src={rightRedGradient}
        alt=""
      />
      <img
        className="gradient absolute top-0 right-0 sm:inline-block hidden"
        src={rightPurple1Gradient}
        alt=""
      />
      <img
        className="gradient absolute top-0 right-0 sm:inline-block hidden"
        src={rightPurple2Gradient}
        alt=""
      />
      <img
        className="gradient absolute top-[-18dvw] left-0 sm:inline-block hidden"
        src={leftPurpleGradient}
        alt=""
      />
      <h1>what you can do on cur8</h1>
      <div className="app-item flex relative sm:h-[130dvw] h-[360dvw]">
        {appItemsLists?.map((list) => (
          <Cards key={list.id} data={list} />
        ))}
          <Particles particles={particles} particleYscroll={particleYscroll}/>
      </div>
    </div>
  );
};

export default AppItemsList;

const particles = [
  { id: 1, src: blueSm },
  { id: 2, src: redSm },
  { id: 3, src: purpleSm },
  { id: 4, src: purpleSm },
  { id: 5, src: redSm },
  { id: 6, src: blueSm },
  { id:7, src: purpleSm },
  { id: 8, src: redMd },
  { id: 9, src: purpleSm },
  { id: 10, src: blueSm },
  { id: 11, src: purpleSm },
  { id: 12, src: redSm },
  { id: 13, src: redMd },

];

const appItemsLists = [
  {
    id: 1,
    backgroundImage: literatureBg,
    icon: literatureIcon,
    name: "Literature",
    size: "md",
  },
  {
    id: 2,
    backgroundImage: listenBg,
    icon: listenIcon,
    name: "Listen",
    size: "xl",
  },
  {
    id: 3,
    backgroundImage: displayBg,
    icon: displayIcon,
    name: "Display",
    size: "lg",
  },
  {
    id: 4,
    backgroundImage: sportsBg,
    icon: sportsIcon,
    name: "Sports",
    size: "sm",
  },
  {
    id: 5,
    backgroundImage: collectionBg,
    icon: collectionIcon,
    name: "Collection",
    size: "sm",
  },
  {
    id: 6,
    backgroundImage: collectiblesBg,
    icon: collectiblesIcon,
    name: "Collectibles",
    size: "xl",
  },
  {
    id: 7,
    backgroundImage: eventsBg,
    icon: eventsIcon,
    name: "Events",
    size: "md",
  },
  {
    id: 8,
    backgroundImage: booksBg,
    icon: booksIcon,
    name: "Books",
    size: "xl",
  },
  {
    id: 9,
    backgroundImage: artBg,
    icon: artIcon,
    name: "Art",
    size: "md",
  },
  // {
  //   id: 10,
  //   backgroundImage: galleriesBg,
  //   icon: galleriesIcon,
  //   name: "Galleries",
  //   size: "sm",
  // },
  {
    id: 11,
    backgroundImage: musicBg,
    icon: musicIcon,
    name: "Music",
    size: "md",
  },
  {
    id: 12,
    backgroundImage: moviesBg,
    icon: moviesIcon,
    name: "Movies",
    size: "xl",
  },
  {
    id: 13,
    backgroundImage: museumsBg,
    icon: museumsIcon,
    name: "Curators",
    size: "sm",
  },
  {
    id: 14,
    backgroundImage: photographyBg,
    icon: photographyIcon,
    name: "Photography",
    size: "xl",
  },
];
