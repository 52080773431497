import React, { useRef } from "react";
import { motion } from "framer-motion";
import "../../Carousel.scss";
import { titleVariants } from "../../motion";
import { useNavigate } from "react-router-dom";
import EvenSlide from "../EvenSlide";
import OddSlide from "../OddSlide";
import HorizontalScrollSection from "App/Components/HorizontalScroll";

const DesktopSection = ({ carouselList }) => {
  const navigate = useNavigate();
  const carouselRef = useRef(null);

  const knowMoreHandler = (e) => {
    let link = e.target.dataset["link"];
    navigate(link);
  };

  return (
    <div
      ref={carouselRef}
      className={`carousel pt-[8.5rem] sm:block hidden`}
    >
      <HorizontalScrollSection className="h-[100dvw]">
        <div className="flex gap-8">
        {carouselList?.map((slide, i) => {
          return (
            <>
              {(i + 1) % 2 !== 0 ? (
                <div className={`w-full h-full min-w-[100dvw] slider`}>
                  <div className={`w-[50%] flex gap-4 justify-between items-center ml-auto`}>
                    <>
                      <h2
                      >
                        {slide?.title}
                      </h2>
                    </>
                  </div>

                  <OddSlide
                    data={slide}
                    currentSlide={true}
                    knowMoreHandler={knowMoreHandler}
                    className={"w-full"}
                  />
                </div>
              ) : (
                <div className="w-full h-full min-w-[100dvw] slider">
                  <div className={"w-[40%] flex gap-4 justify-end items-center mr-auto"}>
                    <h2
                    >
                      {slide?.title}
                    </h2>
                  </div>

                  <EvenSlide
                    data={slide}
                    currentSlide={true}
                    knowMoreHandler={knowMoreHandler}
                    className={"w-full"}
                  />
                </div>
              )}
            </>
          );
        })}
        </div>
      </HorizontalScrollSection>
    </div>
  );
};

export default DesktopSection;
