import { useEffect } from "react";
import HeroSection from "./Components/HeroSection";
import SocialMediaButtonsSection from "./Components/SocialMediaButtons";


const Contact = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => window.scrollTo(0, 0);
  }, []);

  return (
    <div className="collectors flex flex-col">
        <HeroSection />
        <div className="mt-[6.25rem] md:mb-0 mb-[5rem]">
        <SocialMediaButtonsSection/>
        </div>
    </div>
  );
};

export default Contact;
