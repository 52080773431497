import Banner from "./Components/Banner";
import CardsList from "./Components/CardsList";

const TeamSection = ({ data }) => {
  return (
    <div className="team-section">
     <Banner/>
     <CardsList/>
    </div>
  );
};

export default TeamSection;
