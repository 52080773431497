import redGradient from "Assets/Images/collectors/hero-red-gradient.webp";
import purpleGradient from "Assets/Images/collectors/hero-purple-gradient.webp";
import { motion, useScroll, useTransform } from "framer-motion";
import "./HeroSection.scss";
import { gradiantVariants } from "./motion";
import { useRef } from "react";

const HeroSection = () => {
  const textRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: textRef,
    offset: ["start start", "end start"],
  });
  const textLeftXScroll = useTransform(scrollYProgress, [1, 0], [-50, 0]);
  const textRightXScroll = useTransform(scrollYProgress, [1, 0], [50, 0]);

  return (
    <div className="collectors-hero overflow-hidden" ref={textRef}>
      <motion.img
        variants={gradiantVariants}
        initial="initial"
        whileInView="visible"
        className="gradient hero-floating-image"
        src={purpleGradient}
        alt=""
      />
      <motion.img
        variants={gradiantVariants}
        initial="initial"
        whileInView="visible"
        className="gradient hero-floating-image"
        src={redGradient}
        alt=""
      />
      <div className="text-wrapper">
        <motion.h1 style={{ x: textLeftXScroll }}>Cur8 for</motion.h1>
        <motion.h1 style={{ x: textRightXScroll }}>Collectors</motion.h1>
      </div>
    </div>
  );
};
export default HeroSection;
