import VideoSection from "./Components/VideoSection";
import { motion, useScroll, useTransform } from "framer-motion";
import "./VideoAndTextSection.scss";
import useScreenSize from "App/Hooks/ScreenSize";
import { useMemo, useRef } from "react";
import TextRevealing from "App/Components/TextRevealing";

const VideoAndTextSection = () => {
  const screenSize = useScreenSize()?.width;
  const textRef = useRef(null);
  const descriptionRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: textRef,
    offset: ["start end", "end start"],
  });
  const leftTitleXScrollWeb = useTransform(
    scrollYProgress,
    [0, 0.4, 1],
    [-80, 0, 0]
  );
  const leftTitleXScrollMobile = useTransform(
    scrollYProgress,
    [0, 0.4, 1],
    [-20, 0, 0]
  );
  const rightTitleXScrollWeb = useTransform(
    scrollYProgress,
    [0, 0.4, 1],
    [80, 0, 0]
  );
  const rightTitleXScrollMobile = useTransform(
    scrollYProgress,
    [0, 0.4, 1],
    [30, 0, 0]
  );
  const leftTitleXSecondScrollWeb = useTransform(
    scrollYProgress,
    [0, 0.4, 1],
    [-80, 0, 0]
  );
  const leftTitleXSecondScrollMobile = useTransform(
    scrollYProgress,
    [0, 0.4, 1],
    [-20, 0, 0]
  );
  const scrollYDescroptionProgress = useScroll({
    target: descriptionRef,
    offset: ["start end", "end start"],
  });
  const textWebYScroll = useTransform(
    scrollYDescroptionProgress?.scrollYProgress,
    [1, 0.4, 0],
    [0, -80, -200]
  );
  const textMobYScroll = useTransform(
    scrollYDescroptionProgress?.scrollYProgress,
    [1, 0.4, 0],
    [0, 0, 0]
  );
  const leftTitleXScroll = useMemo(() => {
    return screenSize > 768 ? leftTitleXScrollWeb : leftTitleXScrollMobile;
  }, [screenSize, leftTitleXScrollWeb, leftTitleXScrollMobile]);

  const rightTitleXScroll = useMemo(() => {
    return screenSize > 768 ? rightTitleXScrollWeb : rightTitleXScrollMobile;
  }, [screenSize, rightTitleXScrollWeb, rightTitleXScrollMobile]);

  const leftTitleXSecondScroll = useMemo(() => {
    return screenSize > 768 ? leftTitleXSecondScrollWeb : leftTitleXSecondScrollMobile;
  }, [screenSize, leftTitleXSecondScrollWeb, leftTitleXSecondScrollMobile]);

  const textWebXScroll = useTransform(
    scrollYDescroptionProgress?.scrollYProgress,
    [1, 0],
    [0, 0]
  );
  
  const textMobXScroll = useTransform(
    scrollYDescroptionProgress?.scrollYProgress,
    [1, 0.3, 0],
    [0, 0, 700]
  );

  const textXScroll = useMemo(() => {
    return screenSize > 768 ? textWebXScroll : textMobXScroll;
  }, [screenSize, textWebXScroll, textMobXScroll]);

  const videoXScroll = useTransform(
    scrollYDescroptionProgress?.scrollYProgress,
    [1, 0.4, 0],
    [0, 0, -1000]
  );

  return (
    <div className="video-and-text-wrapper" loading="lazy">
      <div className="heading" ref={textRef}>
        <motion.h1 style={{ x: leftTitleXScroll }}>Become a</motion.h1>
        <motion.h1 style={{ x: rightTitleXScroll }}>Digital </motion.h1>
        <motion.h1 style={{ x: leftTitleXSecondScroll }}>Curator</motion.h1>
        <h4>Showcase your digital assets anywhere at anytime.</h4>
      </div>
      <div
        className="flex md:gap-4 gap-[2rem] w-full content"
        ref={descriptionRef}
      >
        <div className="md:w-[50%] w-full md:order-1 order-2 md:px-0 sm:px-[3rem] px-[2rem]">
          <div className="video-content">
            <VideoSection />
          </div>
        </div>
        <div className="md:w-[50%] w-full flex items-center md:pr-[8%] sm:px-[5rem] px-[2rem] md:pl-0 text-left md:order-2 order-1">
          <p>
            The Cur8 platform and the rise of blockchain technology has
            democratized the field of digital curation. Now, anyone can curate
            their own digital gallery, much like playlists for music. Select
            works that match their personal taste or investment goals, and share
            their collections with a global audience. This shift allows more
            people to engage with and shape the digital art scene, bringing
            diverse perspectives to the forefront.
          </p>
        </div>
      </div>
    </div>
  );
};
export default VideoAndTextSection;
