import topRedGradient from "Assets/Images/about/about-top-red.png";
import topPurpleGradient from "Assets/Images/about/about-top-purple.png";
import heroImg from "Assets/Images/about/hero-image.png";
import blueMd from "Assets/Images/creators/particles/particle-glass-blue-md.png";
import purpleMd from "Assets/Images/creators/particles/particle-glass-purple-md.png";
import redMd from "Assets/Images/creators/particles/particle-glass-red-md.png";
import "./HeroSection.scss";
import Particles from "App/Components/Particles";
import { useRef } from "react";
import { useScroll, useTransform } from "framer-motion";

const HeroSection = () => {
  const particles = [
    { id: 1, src: blueMd },
    { id: 2, src: purpleMd },
  ];
  const containerRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["start end", "end start"],
  });
  const particleYscroll = useTransform(scrollYProgress, [0, 1], ["0%", "50%"]);
  return (
    <div className="about-hero pt-[6rem] relative w-full px-8" ref={containerRef}>
      <img
        className="gradient absolute top-0 right-[11rem] sm:inline-block hidden"
        src={topRedGradient}
        alt=""
      />
      <img
        className="gradient absolute top-0 right-0 sm:inline-block hidden"
        src={topPurpleGradient}
        alt=""
      />
      <div className="md:max-w-[85rem] max-w-full  w-full mx-auto">
        <h1 className="md:w-[50%] md:text-right text-left">The digital </h1>
        <h1 className="text-left md:hidden block">revolution </h1>
        <h1 className="text-left md:hidden block">is here </h1>
        <div className="flex gap-[2rem] w-full mt-[1.3rem] md:flex-row flex-col">
          <div className="md:w-[40%] w-full pt-[1.5rem] md:pr-[4rem] pr-0 flex justify-end">
            <div className="relative hero-image-wrapper">
              <img className="hero-img" src={heroImg} alt="" />
              <Particles
                particles={[{ id: 1, src: redMd }]}
                particleYscroll={particleYscroll}
              />
            </div>
          </div>
          <div className="md:w-[60%] w-full flex flex-col gap-[2.8rem] text-wrapper relative">
            <div>
              <h1 className="text-left md:block hidden">revolution </h1>
              <h1 className="text-left md:block hidden">is here </h1>
            </div>
            <p>
              In the future, digital tokenization is expected to revolutionize
              the way we manage and transact assets, extending its reach across
              virtually every asset class. By 2030, experts predict that over
              60% of global physical assets will have a digital counterpart,
              transforming sectors like real estate, art, and even personal
              property into highly liquid and tradable assets.
            </p>
            <Particles
              particles={particles}
              particleYscroll={particleYscroll}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default HeroSection;
