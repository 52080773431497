
import { motion, useScroll, useTransform } from "framer-motion";
import "./ImageBannerAndTextBox.scss";
import purpleBar from "Assets/Images/creators/be-discovered-purple-bar.png";
import redBar from "Assets/Images/creators/be-discovered-red-bar.png";
import useScreenSize from "App/Hooks/ScreenSize";
import { useMemo, useRef } from "react";
import bannerImg from "Assets/Images/creators/be-discovered.jpeg";


const ImageBannerAndTextBox = () => {
  const screenSize = useScreenSize()?.width;
  const textRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: textRef,
    offset: ["start end", "end start"],
  });
  const textXScroll = useTransform(scrollYProgress, [0, 0.4, 1], [50, 0, 0]);
  const leftBarWebYScroll = useTransform(
    scrollYProgress,
    [1, 0.5, 0],
    [40, 0, 0]
  );
  const rightBarWebYScroll = useTransform(
    scrollYProgress,
    [1, 0.5, 0],
    [-40, 0, 0]
  );
  const leftBarMobYScroll = useTransform(
    scrollYProgress,
    [1, 0.5, 0],
    [0, 0, 0]
  );
  const rightBarMobYScroll = useTransform(
    scrollYProgress,
    [1, 0.5, 0],
    [0, 0, 0]
  );

  const leftBarYScroll = useMemo(() => {
    return screenSize > 768 ? leftBarWebYScroll : leftBarMobYScroll;
  }, [screenSize, leftBarWebYScroll, leftBarMobYScroll]);

  const rightBarYScroll = useMemo(() => {
    return screenSize > 768 ? rightBarWebYScroll : rightBarMobYScroll;
  }, [screenSize, rightBarWebYScroll, rightBarMobYScroll]);

  const imgRef = useRef(null);
  const imageScrollProgress = useScroll({
    target: imgRef,
    offset: ["start end", "end start"],
  });
  const imageXScrollWeb = useTransform(
    imageScrollProgress.scrollYProgress,
    [0, 0.4, 1],
    [-50, 0, 0]
  );
  const imageXScrollMobile = useTransform(
    imageScrollProgress.scrollYProgress,
    [0, 0.4, 1],
    [-20, 0, 0]
  );
  const imageXScroll = useMemo(() => {
    return screenSize > 768 ? imageXScrollWeb : imageXScrollMobile;
  }, [screenSize, imageXScrollWeb, imageXScrollMobile]);
  return (
    <div className="creators-discovered pl-[2.875rem] pr-[4.125rem]">
        <div className="md:px-0 sm:px-[2rem] px-4 md:order-1 order-2">
        <motion.div
          className="img-wrapper"
          ref={imgRef}
          style={{ x: imageXScroll }}
        >
          <img loading="lazy" src={bannerImg} alt="" />
        </motion.div>
      </div>
      <motion.div
        ref={textRef}
        className="text-section flex"
        style={{ x: textXScroll }}
      >
        <motion.img
          className="absolute right-bar gradient-bar"
          src={purpleBar}
          alt=""
          style={{ y: leftBarYScroll}}
        />
        <motion.img
          className="absolute left-bar gradient-bar"
          src={redBar}
          alt=""
          style={{ y: rightBarYScroll}}
        />
        <h2 className="md:w-[28.5rem] w-full">Be discovered by collectors and gallerists</h2>
        <div className="details w-full md:w-[28.5rem] flex flex-col md:gap-[2.5rem] gap-4">
          <p>
            Showcase and monetise your creations and collections. Gain global exposure and recognition from collectors and gallerists through a seamless, curated display platform.
          </p>
        </div>
      </motion.div>
      
    </div>
  );
};
export default ImageBannerAndTextBox;
