import "./CardsList.scss";
import aaron from "Assets/Images/about/team/aaron.png";
import david from "Assets/Images/about/team/david.png";
import anand from "Assets/Images/about/team/anand.png";
import matt from "Assets/Images/about/team/matt.png";
import dani from "Assets/Images/about/team/dani.png";
import Card from "../Card";
import { useCallback, useRef } from "react";
import { useScroll, useTransform } from "framer-motion";
import blueMd from "Assets/Images/about/particles/particle-glass-blue-medium-sm.png";
import purpleSm from "Assets/Images/about/particles/particle-glass-purple-sm.png";
import purpleXSm from "Assets/Images/about/particles/particle-glass-purple-extra-sm.png";
import redSm from "Assets/Images/creators/particles/particle-glass-red-sm.png";
import redMd from "Assets/Images/about/particles/particle-glass-red-md.png";
import redLg from "Assets/Images/creators/particles/particle-glass-red-md.png";
import purpleMd from "Assets/Images/creators/particles/particle-glass-purple-md.png";
import Particles from "App/Components/Particles";
import useScreenSize from "App/Hooks/ScreenSize";
const CardsList = () => {
  const screenSize = useScreenSize()?.width;

    const getAlignment= useCallback((index)=>{
      if(screenSize>550){
        index += 1;
  
        // Calculate the position in the 6-element repeating pattern
        const positionInPattern = (index - 1) % 6 + 1;
        
        // Determine the alignment based on the position
        if (positionInPattern === 1 || positionInPattern === 4 || 
            positionInPattern === 5) {
          return 'left-align';
        } else {
          return 'right-align';
        }
      }
      return (index+1)%2===0?"right-align":"left-align"
       
    },[screenSize])
    const containerRef = useRef(null);
    const { scrollYProgress } = useScroll({
      target: containerRef,
      offset: ["start end", "end start"],
    });
    const cardYScroll = useTransform(
      scrollYProgress,
      [0,  1],
      ["0%","-25%"]
    );
    const { scrollYProgress: scrollParticleYProgress } = useScroll({
      target: containerRef,
      offset: ["start end", "end start"],
    });
    const particleYscroll = useTransform(
      scrollParticleYProgress,
      [0,  1],
      ["0%","150%"]
    );
  return (
    <div className="cards-list mt-[10dvw] relative" ref={containerRef}>
      {data?.map((list,i) => (
        <Card key={list?.id} cardYScroll={cardYScroll} data={list} className={getAlignment(i)} />
      ))}
      <Particles particles={particles} particleYscroll={particleYscroll}/>
    </div>
  );
};

export default CardsList;

const data = [
  {
    id: 1,
    name: "Aaron Arfman",
    image: aaron,
    role: "Co-Founder",
    details: "Likes to Cur8 gen art, wine and experiences.",
  },
  {
    id: 2,
    name: "David Harris",
    image: david,
    role: "Co-Founder",
    details: "Likes to Cur8 novels, records and restaurants.",
  },
  {
    id: 3,
    name: "Matt Hook",
    image: matt,
    role: "Technical Lead",
    details: "Likes to Cur8 on-chain generative art and music.",
  },
  {
    id: 4,
    name: "Anand Venkitaraman",
    image: anand,
    role: "Project Manager",
    details: "Likes to Cur8 music, books and art.",
  },
  {
    id: 5,
    name: "Danielle Erasmus Zinn",
    image: dani,
    role: "Creative Director",
    details: "Likes to Cur8 music, words and outrageous images.",
  }
];


const particles = [
  { id: 1, src: blueMd ,size:"md"},
  { id: 2, src: purpleXSm ,size:"sm"},
  { id: 3, src: redMd,size:"md" },
  { id: 4, src: purpleMd,size:"md" },
  { id: 5, src: redLg,size:"lg" },
  { id: 6, src: blueMd ,size:"md"},
  { id: 7, src: purpleXSm,size:"sm" },
  { id: 8, src: redSm ,size:"sm"},
  { id: 9, src: redSm,size:"sm" },
  { id: 10, src: purpleSm,size:"sm" },
  { id: 11, src: purpleSm ,size:"sm"},
  { id: 12, src: blueMd,size:"md" },
  { id: 13, src: blueMd ,size:"md"},
];