import "./TitleAndDescription.scss";

const TitleAndDescription = ({ data }) => {
  return (
    <div className="title-and-description flex flex-col gap-[1.5rem]">
      <h1>{data?.title}</h1>
      <p>{data?.description}</p>
    </div>
  );
};

export default TitleAndDescription;
