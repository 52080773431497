import "./Card.scss";
import { motion } from "framer-motion";

const Card = ({ data, className, cardYScroll }) => {
  return (
    <div className={`team-card ${className}`}>
      <div className="image-wrapper">
        <img src={data?.image} alt="" />
      </div>
      <motion.div style={{ y: cardYScroll }} className="details">
        <h1 className="text-left">{data?.name}</h1>
        <h2 className="text-left">{data?.role}</h2>
        <p className="text-left">{data?.details}</p>
      </motion.div>
    </div>
  );
};
export default Card;
