import { useEffect, useState } from "react";
import Carousel from "./Components/Carousel";
import HeroSection from "./Components/HeroSection";
import ImageHeadingDescriptionSection from "./Components/ImageHeadingDescription";
import HowItWorksSection from "./Components/HowItWorksSection";
import useScreenSize from "App/Hooks/ScreenSize";

const Collectors = () => {
  const screenSize = useScreenSize()?.width
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => window.scrollTo(0, 0);
  }, []);
  const [showCarousel, setShowCarousel] = useState(false);
  useEffect(() => {
    if(screenSize<=768) setShowCarousel(true);
    setTimeout(() => {
      setShowCarousel(true);
    }, 1000);
  }, [screenSize]);
  return (
    <div className="collectors flex flex-col gap-[15dvw]">
      <section className="mobile-normal">
        <HeroSection />
       <Carousel />
      </section>
      {/* <HowItWorksSection/> */}
      <ImageHeadingDescriptionSection/>
    </div>
  );
};

export default Collectors;
