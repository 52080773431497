import "./Cards.scss";

const Cards = ({ data }) => {
  return (
    <div
      className={`app-items-cards ${data?.size}`}
      style={{ backgroundImage: `url(${data?.backgroundImage})` }}
    >
      <div className="icon-wrapper">
        <img src={data?.icon} alt="" />
      </div>
      <h5>{data?.name}</h5>
    </div>
  );
};

export default Cards;
