import { motion, useScroll, useTransform } from "framer-motion";
import homeGradientOne from "Assets/Images/home/home-1.webp";
import homeGradientTwo from "Assets/Images/home/home-2.webp";
import downArrow from "Assets/Images/down-arrow.svg";
import "./HeroSection.scss";
import { useEffect, useRef, useState } from "react";
import heroImg from "Assets/Images/home/main.jpg";
import lowResHeroImg from "Assets/Images/home/main.webp";
import heroVideo from "Assets/Videos/hero-video.mp4";
const HeroSection = () => {
const heroImgRef = useRef(null)
const [isVideoLoaded,setIsVideoLoaded]= useState(false);
  const { scrollYProgress } = useScroll({
    target: heroImgRef,
    offset: ["start start", "end start"],
  });
  const yBackground = useTransform(scrollYProgress, [0, 1], ["0%", "100%"]);

  const mainHeadingRef=useRef();
  const smallDescriptionRef= useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // Add animation or transition logic here
          entry.target.classList.add('animation-h1');
        } else {
          entry.target.classList.remove('animation-h1');
        }
      });
    }, { threshold: 0.5 }); // Adjust threshold as needed
    const descObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // Add animation or transition logic here
          entry.target.classList.add('animation-desc');
        } else {
          entry.target.classList.remove('animation-desc');
        }
      });
    }, { threshold: 0.5 }); // Adjust threshold as needed
    if (mainHeadingRef.current) {
      observer.observe(mainHeadingRef.current);
    }
    if (smallDescriptionRef.current) {
      descObserver.observe(smallDescriptionRef.current);
    }

    return () => {
      if (mainHeadingRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(mainHeadingRef.current);
      }
      if (smallDescriptionRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        descObserver.unobserve(smallDescriptionRef.current);
      }
    };
  }, []);
  const scrollDownToBriefDescriptionHandler=()=>{
    const root = document.getElementById('brief');
    root.scrollIntoView();
  }
  // useEffect(() => {
  //   heroImgRef.current.style.backgroundImage = `url(${lowResHeroImg})`;
  //   const img = new Image();
  //   img.src = heroImg;
  //   img.onload = () => {
  //     heroImgRef.current.style.backgroundImage = `url(${heroImg})`;
  //   };
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [heroImg]);
  const handleVideoLoad = () => {
    setIsVideoLoaded(true);
  };
  return (
    <div className="hero-page h-full relative" ref={heroImgRef}>
      {!isVideoLoaded&&<img className="hero-dummy-img" src={lowResHeroImg} alt=""/>}
      <video autoPlay loop muted className="background-video"  onLoadedData={handleVideoLoad}>
        <source src={heroVideo} type="video/mp4" />
      </video>
      <motion.img
        className="absolute top-0 left-0"
        src={homeGradientOne}
        initial={{ opacity: 0.5, x: -300 }}
        whileInView={{ opacity: 1, x: 0, transition: { duration:1 } }}
        alt=""
      />
      <motion.div
        className="content flex flex-col gap-[2.5rem] items-center"
         style={{ y: yBackground }}
      >
        <div>
          <h1 ref={mainHeadingRef}>
            <span>C</span>
            <span>u</span>
            <span>r</span>
            <span>a</span>
            <span>t</span>
            <span>e</span>
            <span>&nbsp;</span>
            <span>t</span>
            <span>h</span>
            <span>e</span>
            <br />
            <span>w</span>
            <span>o</span>
            <span>r</span>
            <span>l</span>
            <span>d’s</span>
            <span>&nbsp;</span>
            <span>d</span>
            <span>i</span>
            <span>g</span>
            <span>i</span>
            <span>t</span>
            <span>a</span>
            <span>l</span>
            <br />
            <span>a</span>
            <span>s</span>
            <span>s</span>
            <span>e</span>
            <span>t</span>
            <span>s</span>
          </h1>
        </div>
        <div>
          <p>Discover, curate, and (dis)play</p>
          <p> your digital collections</p>
        </div>
        <img onClick={scrollDownToBriefDescriptionHandler}
          className="down-arrow cursor-pointer"
          src={downArrow}
          alt=""
        />
      </motion.div>
      <motion.img
        className="absolute top-0 right-0"
        src={homeGradientTwo}
        initial={{ opacity: 0}}
        whileInView={{ opacity: 1,  transition: { duration: 1 } }}
        alt=""
        custom={"right"}
      />
    </div>
  );
};
export default HeroSection;
