export const titleVariants = {
    initial: {
      opacity: 0,
      x:80
    },
    visible: {
      opacity: 1,
      x:0,
      transition: {
        duration: 1,
        delay: 0.5,
        straggerChildren: 0.1,
      },
    },
  };
  export const slideButtonVariants = {
    initial: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 1.5,
        delay: 0.5,
      },
    },
  };
  export const leftKnowMoreBtnVariants = {
    initial: {
      opacity: 0,
      x: -80,
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 3,
        delay: 0.5,
        straggerChildren: 0.1,
      },
    },
  };
  export const rightKnowMoreBtnVariants = {
    initial: {
      opacity: 0,
      x: 80,
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 3,
        delay: 0.5,
        straggerChildren: 0.1,
      },
    },
  };
  export const slideWebImg1Variants = {
    initial: {
      opacity: 0,
      x: -80,
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 2,
        delay: 0.5,
        straggerChildren: 0.1,
      },
    },
  };
  export const mobSlideImg1Variants = {
    initial: {
      opacity: 0,
      x: -100,
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 1.5,
        delay: 0.5,
        straggerChildren: 0.1,
      },
    },
  };
  export const slideImg2Variants = {
    initial: {
      opacity: 0,
      x: 80,
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 2,
        delay: 0.5,
        straggerChildren: 0.1,
      },
    },
  };

  export const gradientVariants = {
    initial: {
      opacity: 0,
      scale: 0,
    },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 3,
        delay: 0.75,
        straggerChildren: 0.1,
      },
    },
  };

