import HorizontalScrollSection from "App/Components/HorizontalScroll";
import slideOne from "Assets/Images/creators/cur8-for-creators1.jpg";
import slideTwo from "Assets/Images/creators/cur8-for-creators2.jpg";
import "./Carousel.scss";
import ImageBlock from "App/Pages/Collectors/Components/Carousel/Components/ImageBlock";


const Carousel = () => {
  const slider = [
    { id: 1, src: slideOne },
    { id: 2, src: slideTwo },
  ];
  let TextBlocks = (
    <div className="text-wrapper slider flex md:justify-center justify-start">
      <div className="flex flex-col gap-8 max-w-[20.375rem]">
      <h4>The artist unleashed. </h4>
      <p className="text-left">Publish, promote, curate, share and sell art free of traditional constraints and controls.</p>
      </div>
    </div>
  );
  return (
    <div className="creators-carousel hero-section-carousel relative">
      <HorizontalScrollSection className={"md:block hidden h-[100dvw]"} >
        <div className="flex gap-4">
        {TextBlocks}
        {slider?.map((slide, index) => (
          <ImageBlock slide={slide} index={index} />
        ))}
        </div>
      </HorizontalScrollSection>
      <div className="md:hidden flex flex-col overflow-hidden">
      {TextBlocks}
        <div className="mobile-image-block">
          {slider?.map((slide, index) => (
            <ImageBlock
              oddMobScrollVariant={{
                offset: [1, 0.1, 0],
                value: [0, 0, -30],
              }}
              evenMobScrollVariant={{
                offset: [1, 0.1, 0],
                value: [0, 0, 30],
              }}
              slide={slide}
              index={index}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
export default Carousel;
