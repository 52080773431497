export const titleLeftVariants = {
  initial: {
    opacity: 0,
    x: -300,
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 1.5,
      delay: 0.5,
      straggerChildren: 0.1,
    },
  },
};
export const titleRightVariants = {
  initial: {
    opacity: 0,
    x: 300,
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 1.5,
      delay: 0.5,
      straggerChildren: 0.1,
    },
  },
};
export const gradiantVariants = {
  initial: {
    opacity: 0,
    y: -300,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 1.5,
    },
  },
};
