import "./Banner.scss";
import bannerImg from "Assets/Images/about/our-team-bg.png";
import bannerRightPurple from "Assets/Images/about/banner-right-purple.png";
import bannerRightRed from "Assets/Images/about/banner-right-red.png";
import bannerCenterRed from "Assets/Images/about/banner-center-red.png";

const Banner = () => {
  return (
    <div className="team-banner">
      <img src={bannerRightPurple} className="absolute right-0 banner-gradient" alt="" />
      <img src={bannerRightRed} className="absolute  right-0 banner-gradient" alt="" />
      <img src={bannerCenterRed} className="absolute banner-gradient" alt="" />
      <img className="banner-image" src={bannerImg} alt="" />
      <h1>Our Team & Advisors</h1>
    </div>
  );
};

export default Banner;
