import TitleAndDescription from "./Components/TitleAndDescription";
import rightRedGradient from "Assets/Images/about/title-description-right-red-gradient.png";
import "./TitleAndDescriptionSection.scss";

const TitleAndDescriptionSection = () => {
  return (
    <div className="title-and-description-section flex flex-col gap-[2.875rem] relative">
      <img src={rightRedGradient} className="absolute top-[-15dvw] right-0 sm:inline-block hidden" alt="" />
      {content?.map((data) => (
        <TitleAndDescription key={data?.id} data={data} />
      ))}
    </div>
  );
};

export default TitleAndDescriptionSection;

const content = [
  {
    id: 1,
    title: "The Future",
    description:
      " Envision a scenario where nearly every asset, from homes to artwork, can have a digital counterpart. This transformation isn't just about speeding up transactions; it's about broadening the possibilities for investment and shared ownership. The adoption of blockchain technology adds layers of transparency and security. We're moving toward a reality where fractional ownership of assets, such as pieces of art or real estate, is as simple as a few clicks on a smartphone. This shift is set to redefine our concepts of ownership and collaboration.",
  },
  {
    id: 2,
    title: "The Need for Curation",
    description:
      "As digital tokenization expands, the need for a curation platform like Cur8 becomes essential. Cur8 helps organize and display high-quality visual assets, making it simple for users to explore and enjoy verified collections.",
  },
];
