import { useEffect, useState } from "react";
import "./App.scss";
import Main from "./Layouts/Main";
import ZenKakuGothicAntique from "Assets/Fonts/ZenKakuGothicAntique-Regular.ttf";

function App() {
  const [fontLoaded, setFontLoaded] = useState(false);

  useEffect(() => {
    // Function to check if font is loaded
    const font = new FontFace(
      "ZenKakuGothicAntique-Regular",
      `url(${ZenKakuGothicAntique})`
    );
    font
      .load()
      .then(() => {
        document.fonts.add(font);
        setTimeout(() => {
          setFontLoaded(true);
        }, 1000);
      })
      .catch((error) => {
        console.error("Font failed to load:", error);
      });
  }, []);
  if (!fontLoaded) return;
  return (
    <div className="App h-full">
      <Main />
    </div>
  );
}

export default App;
