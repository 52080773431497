import React, { useRef } from "react";
import "./HorizontalScroll.scss";
import {motion, useScroll, useTransform} from "framer-motion";

const HorizontalScrollSection = ({ children,className,top="10%" }) => {
  const scrollRef = useRef();
  const { scrollYProgress } = useScroll({
    target: scrollRef,
    offset:["start start","end center start"]

  });
  const xTransform = useTransform(scrollYProgress, [0, 1], ["1%" , "-95%"]);
  return (
    <div ref={scrollRef} className={`slider-section ${className} relative `}>
      <div className="sticky top-[10%] flex items-center overflow-hidden">
     <motion.div className="scroll-content-wrapper" style={{x: xTransform}}>
     {children}
     </motion.div>
      </div> 
     </div>
  );
};

export default HorizontalScrollSection;
