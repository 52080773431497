import artists from "Assets/Images/creators/discover-artists.jpg";
import photography from "Assets/Images/creators/discover-photography.jpg";
import HeadingAndImageBlock from "./Components/HeadingAndImageBlock";
import "./GradientBackgroundSection.scss";
import blueMd from "Assets/Images/creators/particles/particle-glass-blue-md.png";
import blueSm from "Assets/Images/creators/particles/particle-glass-blue-sm.png";
import purpleSm from "Assets/Images/creators/particles/particle-glass-purple-sm.png";
import redMd from "Assets/Images/creators/particles/particle-glass-red-md.png";
import { useScroll, useTransform} from "framer-motion";
import Particles from "App/Components/Particles";
import { useRef } from "react";

const particles = [
  { id: 1, src: purpleSm },
  { id: 2, src: blueMd },
  { id: 3, src: redMd },
  { id: 4, src: purpleSm },
  { id: 5, src: blueSm },
];
const GradientBackgroundSection = () => {

  const data = [
    { id: 1, image: artists, heading: "Discover Artists", link: "/discover/artists",linkText:"See Artists" },
    { id: 2, image: photography, heading: "Discover Photographers", link: "/discover/photographers",linkText:"See Photographers"  },
  ];
  const bgRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: bgRef,
    offset: ["start end", "end start"],
  });
  const particleYscroll = useTransform(
    scrollYProgress,
    [0,  1],
    ["0%","75%"]
  );
  return (
    <div className="gradient-bg-section relative" ref={bgRef}>
      <Particles particles={particles} particleYscroll={particleYscroll} />
      <div className="block-wrapper flex flex-col md:gap-[1.25rem] gap-[4.5rem]">
        {data?.map((list, i) => (
          <HeadingAndImageBlock key={list?.id} index={i + 1} data={list} />
        ))}
      </div>
    </div>
  );
};
export default GradientBackgroundSection;
