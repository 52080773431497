import { useEffect } from "react";
import Carousel from "./Carousel";
import AboutUsSection from "./Components/AboutUsSection";
import BriefDescriptionSection from "./Components/BriefSection";
import HeroSection from "./Components/HeroSection";
import HowItWorksSection from "./Components/HowItWorksSection";
import VideoAndTextSection from "./Components/VideoAndTextSection";
import "./Home.scss";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => window.scrollTo(0, 0);
  }, []);
  return (
    <div className="landing-page relative">
      <section id="hero">
        <HeroSection />
      </section>
      <div id="brief" className="flex w-full justify-center py-[6rem]">
        <BriefDescriptionSection />
      </div>
      <div id="carousel"> <Carousel /></div>
      <div id="howItWorks" className="overflow-hidden">
        <HowItWorksSection />
      </div>
      <div id="digitalCurator"  className="overflow-hidden">
        <VideoAndTextSection />
      </div>
      <div id="aboutUs"  className="overflow-hidden">
        <AboutUsSection />
      </div>
    </div>
  );
};
export default Home;
