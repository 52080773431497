import Carousel from "../Carousel"
import HeadingSection from "./Components/HeadingSection"
import rightTopBlueGradient from "Assets/Images/creators/creators-hero-right-top-blue-gradient.png";
import rightTopPurpleeGradient from "Assets/Images/creators/creators-hero-right-top-purple-gradient.png";
import rightLeftBlueGradient from "Assets/Images/creators/creators-hero-left-blue-gradient.png";
import rightLeftPurpleGradient from "Assets/Images/creators/creators-hero-left-purple-gradient.png";
import rightTopBottomBlueGradient from "Assets/Images/creators/creators-hero-right-bottom-blue-gradient.png";
import rightTopBottomPurpleGradient from "Assets/Images/creators/creators-hero-right-bottom-purple-gradient.png";

const HeroSection=()=>{
    return <div className="creators-hero relative">
      <img
        className="absolute top-0 right-0"
        src={rightTopBlueGradient}
        alt=""
      />
      <img
        className="absolute top-0 right-0"
        src={rightTopPurpleeGradient}
        alt=""
      />
           <img
        className="absolute top-0 leftt-0"
        src={rightLeftBlueGradient}
        alt=""
      />
      <img
        className="absolute top-0 left-0"
        src={rightLeftPurpleGradient}
        alt=""
      />
      <HeadingSection/>
      <Carousel/>
           <img
        className="absolute bottom-0 right-0"
        src={rightTopBottomPurpleGradient}
        alt=""
      />
      <img
        className="absolute bottom-0 right-0"
        src={rightTopBottomBlueGradient}
        alt=""
      />
    </div>
}
export default HeroSection