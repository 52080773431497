export const textVariants = {
    initial: {
      opacity: 0,
      x: -300,
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 1,
        delay: 0.5,
        straggerChildren: 0.1,
      },
    },
  };
  export const imgVariants = {
    initial: {
      opacity: 0,
      x: 400,
      // width:0
    },
    visible: {
      opacity: 1,
      x: 0,
 
      // width:"100%",
      transition: {
        duration: 3,
        delay: 0.5,
        straggerChildren: 0.1,
      },
    },
  };

  export const leftBarVariants = {
    initial: {
      opacity: 0,
      x: -200,
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 2,
        delay: 0.5,
        straggerChildren: 0.1,
      },
    },
  };

  export const MobBarVariants = {
    initial: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 5,
        delay: 0.5,
        straggerChildren: 0.1,
      },
    },
  };

  export const rightBarVariants = {
    initial: {
      opacity: 0,
      x: 300,
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 2,
        delay: 1,
        straggerChildren: 0.1,
      },
    },
  };