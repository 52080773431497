import { useEffect } from "react";
import HeroSection from "./Components/HeroSection";
import AppItemsList from "./Components/AppItemsSection";
import TitleAndDescriptionSection from "./Components/TitleAndDescriptionSection";
import TeamSection from "./Components/TeamSection";


const About = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => window.scrollTo(0, 0);
  }, []);

  return (
    <div className="about flex flex-col">
        <HeroSection />
        <AppItemsList/>
        <TitleAndDescriptionSection/>
        <TeamSection/>
    </div>
  );
};

export default About;
