
import {motion} from "framer-motion";

const Particles = ({particles,particleYscroll}) => {
  return (
    <>
      {particles?.map((img) => (
        <motion.img style={{y:particleYscroll}} className={`particles ${img?.size}`} src={img?.src} alt="" />
      ))}
    </>
  );
};
export default Particles;
