/* eslint-disable react-hooks/exhaustive-deps */

import { motion } from "framer-motion";
import homeGradientThree from "Assets/Images/home/home-3.webp";
import homeGradientFour from "Assets/Images/home/home-4.webp";
import "./BriefDescriptionSection.scss";
import { leftGradientVariants, rightGradientVariants } from "./motion";

import { ScrollText } from "App/Components/ScrollText";
import useScreenSize from "App/Hooks/ScreenSize";
import { useMemo } from "react";

// const text =
//   "Cur8 frees digital assets from constraints and controls, revolutionizing how they are discovered, bought, sold, curated and displayed."


const BriefDescriptionSection = () => {

  const { width } = useScreenSize();

  const text = useMemo(()=>{
    return (width >= 768) ? [
      "Cur8 frees digital assets from", 
      "constraints and controls, revolutionizing", 
      "how they are discovered, bought, sold,", 
      "curated and displayed."
    ] : [
      "Cur8 frees digital assets from", 
      "constraints and controls,", 
      "revolutionizing how they are", 
      "discovered, bought, sold,", 
      "curated and displayed."
    ]
  },[width])


  return (
    <motion.div>
      <motion.img
        className="absolute top-[30vh] left-0 brief-floating-image pointer-events-none"
        src={homeGradientThree}
        variants={leftGradientVariants}
        initial="initial"
        whileInView={"visible"}
        alt=""
      />
      <ScrollText content={text} />
      <motion.img
        className="absolute top-[45vh] right-0 brief-floating-image pointer-events-none"
        src={homeGradientFour}
        variants={rightGradientVariants}
        initial="initial"
        whileInView={"visible"}
        alt=""
      />
      </motion.div>
  );
};

export default BriefDescriptionSection;
