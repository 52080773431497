import HowItWorksSection from "App/Components/HowItWorksSection";
import howItWorkImg from "Assets/Images/home/how-it-work.png";

const HowItWorks = () => {
  return (
    <HowItWorksSection
      title="How it works"
      description={
        "Cur8 is the ultimate marketplace for creators and collectors. \n \n Collectors simply purchase their favorite pieces or connect their collections from any blockchain and elevate the experience of displaying, listening, reading and watching them. \n \n Creators now have a one-stop platform to engage their communities, sell their products and build a global following."
      }
      imageSrc={howItWorkImg}
    />
  );
};
export default HowItWorks;
