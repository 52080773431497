import { scrollToTop } from "App/Helper/utilities";
import { useRef } from "react";
import "./Main.scss";
import Header from "../Header";
import MainRoutes from "App/Routes";
import Footer from "../Footer";

const Main = () => {
  const headerRef = useRef();
  const navExpandHandler = () => {
    setTimeout(() => {
      scrollToTop();
    }, 1000);
  };
  return (
    <div className={`flex flex-col h-full`}>
      <div
        className={`header-wrapper flex h-full items-center relative flex-col`}
        ref={headerRef}
      >
        <Header onHamburgerClick={navExpandHandler} className={"menu-bar"} />
        <div className={`content-wrapper  w-full`} id="mainContent">
          <MainRoutes />
        </div>
        <div className="w-full">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Main;
