import sale from "Assets/Images/creators/sales.jpg";
import royalties from "Assets/Images/creators/royalties.jpg";
import rentals from "Assets/Images/creators/rentals.jpg";
import merchandise from "Assets/Images/creators/merchandise.jpg";
import Card from "./Components/Card";
import "./CardsSection.scss";

const CardsSection = () => {
  const data = [
    { id: 1, image: sale, color: "purple", title: "Sales" },
    { id: 2, image: royalties, color: "red", title: "Royalties" },
    { id: 3, image: rentals, color: "blue", title: "Rentals" },
    { id: 4, image: merchandise, color: "purple", title: "Merchandise" },
  ];
  return (
    <div className="cards-section grid gap-[1.75rem] pointer-events-none">
      {data?.map((card) => (
        <Card key={card?.id} cardData={card} className={`pointer-events-auto ${card?.color}`} />
      ))}
    </div>
  );
};

export default CardsSection;
