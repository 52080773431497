import About from "App/Pages/About";
import Collectors from "App/Pages/Collectors";
import Contact from "App/Pages/Contact";
import Creators from "App/Pages/Creators";
import Home from "App/Pages/Home";
import { Route, Routes } from "react-router-dom";

const MainRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/collectors" element={<Collectors />} />
      <Route path="/creators" element={<Creators />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/about" element={<About />} />
      <Route path="*" element={<Home />} />
    </Routes>
  );
};

export default MainRoutes;
