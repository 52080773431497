import gradientRed from "Assets/Images/contact/contact-gradient-center-2.png";
import gradientBlue from "Assets/Images/contact/contact-gradient-center-1.png";
import gradientPurple from "Assets/Images/contact/contact-gradient-center-3.png";
import gradientLeftBlueMob from "Assets/Images/contact/contact-left-blue-mob.png";
import gradientLeftRedMob from "Assets/Images/contact/contact-left-red-mob.png";
import gradientLeftPurpleMob from "Assets/Images/contact/contact-left-purple-mob.png";
import gradientRightBlueMob from "Assets/Images/contact/contact-right-blue-mob.png";
import gradientRightPurpleMob from "Assets/Images/contact/contact-right-blue-mob.png";
import gradientRightRedMob from "Assets/Images/contact/contact-right-red-mob.png";
import "./SocialMediaButtonsSection.scss";

const SocialMediaButtonsSection = () => {
  const socialMediaList = [
    {
      id: 1,
      text: "Join Discord",
      link: "https://discord.com/invite/vHqfQ5vPFd",
    },
    { id: 1, text: "Follow X", link: "https://twitter.com/Cur8Labs" },
    {
      id: 1,
      text: "Follow Linkedin",
      link: "https://www.linkedin.com/company/82127709/admin/feed/posts/",
    },
  ];
  return (
    <div className="social-media-button-section relative">
      <img
        className="gradient absolute top-[-18rem] right-[10%] md:inline-block hidden pointer-events-none"
        src={gradientPurple}
        alt=""
      />{" "}
      <img
        className="gradient absolute top-[-28rem] right-[13%] md:inline-block hidden pointer-events-none"
        src={gradientRed}
        alt=""
      />
      <img
        className="gradient absolute top-[-23rem] right-0 md:inline-block hidden pointer-events-none"
        src={gradientBlue}
        alt=""
      />
      <img
        className="gradient absolute top-[-23rem] left-0 inline-block md:hidden pointer-events-none"
        src={gradientLeftRedMob}
        alt=""
      />
      <img
        className="gradient absolute top-[-14rem] left-0 inline-block md:hidden pointer-events-none"
        src={gradientLeftBlueMob}
        alt=""
      />{" "}
      <img
        className="gradient absolute top-[-23rem] left-0 inline-block md:hidden pointer-events-none"
        src={gradientLeftPurpleMob}
        alt=""
      />
      <img
        className="gradient absolute top-[-23rem] right-0 inline-block md:hidden pointer-events-none"
        src={gradientRightBlueMob}
        alt=""
      />
      <img
        className="gradient absolute top-[-16rem] right-0 inline-block md:hidden pointer-events-none"
        src={gradientRightRedMob}
        alt=""
      />
      <img
        className="gradient absolute top-[-23rem] right-0 inline-block md:hidden pointer-events-none"
        src={gradientRightPurpleMob}
        alt=""
      />
      <div className="flex flex-col gap-[2.25rem] justify-center relative items-center z-[2]">
        {socialMediaList?.map((el) => (
          <a
            href={el?.link}
            className="blue-red-gradient-button"
            rel="noreferrer"
            target="_blank"
          >
            {el?.text}
          </a>
        ))}
      </div>
    </div>
  );
};

export default SocialMediaButtonsSection;
