import { blockData } from "./Components/Data";
import ImageDetailBlock from "./Components/ImageDetailBlock";
import { motion } from "framer-motion";
import rightRedGradient from "Assets/Images/collectors/block-red-gradient.png";
import rightPurpleGradient from "Assets/Images/collectors/block-purple-gradient.png";
import { gradientVariants } from "./motion";
import "./ImageHeadingDescription.scss";

const ImageHeadingDescriptionSection = () => {
  return (
    <div className="relative overflow-hidden">
      <motion.img
        className="absolute top-0 right-0 block-floating-image"
        src={rightPurpleGradient}
        variants={gradientVariants}
        initial="initial"
        whileInView="visible"
        alt=""
      />
      <motion.img
        className="absolute top-[28dvh] right-0 block-floating-image"
        src={rightRedGradient}
        variants={gradientVariants}
        initial="initial"
        whileInView="visible"
        alt=""
      />
      <div className="image-heading-description-section lg:mt-[16dvh] mt-[15rem] lg:mb-[25dvh] mb-[15rem] mx-[2rem] gap-[9.5rem] flex flex-col">
        {blockData?.map((data, i) => (
          <ImageDetailBlock key={data?.id} data={data} index={i + 1} />
        ))}
      </div>
    </div>
  );
};

export default ImageHeadingDescriptionSection;
