import { motion } from "framer-motion";
import "./OddSlide.scss";
import slider1Gradient1 from "Assets/Images/home/slider-1-gradient-1.webp";
import slider1Gradient2 from "Assets/Images/home/slider-1-gradient-2.webp";
import {
  gradientVariants,
  rightKnowMoreBtnVariants,
  slideWebImg1Variants,
  slideImg2Variants,
  mobSlideImg1Variants,
} from "../../motion";
import Button from "App/Components/Button";
import useScreenSize from "App/Hooks/ScreenSize";
import { useMemo } from "react";

const OddSlide = ({
  currentSlide,
  knowMoreHandler,
  data,
  hideKnowMore,
  className,
}) => {
  const screenSize = useScreenSize()?.width;
  const leftSliderImgVariant = useMemo(() => {
    return screenSize > 768 ? slideWebImg1Variants : mobSlideImg1Variants;
  }, [screenSize]);
  return (
    <div className={`slide active odd-slide ${className}`}>
      <div className={`relative`}>
        {!hideKnowMore && (
          <div
            className="know-more-wrapper"
          >
            <Button
              className="know-more-btn purple-100-bg"
              data-link={data?.urlLink}
              onClick={knowMoreHandler}
            >
              Learn More
            </Button>
          </div>
        )}
        <div
          style={{ backgroundImage: `url(${slider1Gradient1})` }}
          className="gradient img1"
        ></div>
        <div
          style={{ backgroundImage: `url(${data?.sliderImgOne})` }}
          className="image1"
        ></div>

        <div
          style={{ backgroundImage: `url(${data?.sliderImgTwo})` }}
          className="image2"
        ></div>
        <div
          style={{ backgroundImage: `url(${slider1Gradient2})` }}
          className="gradient img2"
        ></div>
      </div>
    </div>
  );
};

export default OddSlide;
