import { useState } from "react";
import "./Header.scss";
import NavbarContent from "./Components/NavbarContent";
import cur8MobLogo from "Assets/Images/cur8-logo.png";

const Header = (props) => {
const [leftMenuCollapse,setMenuCollapse] = useState(false)
const onMenuCollapseHandler=()=>{
  setMenuCollapse(pre=>!pre);
}
  return (
    <div
      id="header-bar"
      className={`title-bar nav-bar z-10 ${leftMenuCollapse && "collpased"} relative`}
    >
      <div className="md:block hidden w-full">
        <NavbarContent/>
      </div>
      <div className="md:hidden block w-full">
       <div className="flex">
       <div className="logo-box ml-auto cursor-pointer pointer-events-auto" onClick={onMenuCollapseHandler}>
          <img loading="lazy" src={cur8MobLogo} alt=""/>
        </div>
       </div>
        <div className={`menu-collapse ${leftMenuCollapse && "active"}`}>
        <NavbarContent setMenuCollapse={setMenuCollapse}/>
        </div>
      </div>
    </div>
  );
};

export default Header;
