import { useRef, useState } from "react";
import "./VideoSection.scss";
import Player from "App/Components/Player";
import { exitFullscreen, fullScreenHandler } from "App/Helper/utilities";

const VideoSection = () => {
  const videoRef = useRef(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const handleButtonClick = () => {
    if (isVideoPlaying) {
      pauseVideoHanlder();
    } else {
      playVideoHandler();
    }
  };
  const playerRef = useRef(null);
  const playVideoHandler = () => {
    setIsVideoPlaying(true);
    const video = videoRef.current;
    fullScreenHandler(video);
  };

  // Function to pause the video
  const pauseVideoHanlder = () => {
    setIsVideoPlaying(false);
    exitFullscreen();
  };

  return (
    <div className="video-wrapper">
      <div
        onClick={handleButtonClick}
        className={`video-button ${isVideoPlaying && "active"}`}
      >
        <div className="video-container" ref={videoRef}>
          {/* <video
            ref={videoRef}
            className="actual-video"
            src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/502545/Oculus.mp4"
          //  poster="https://assets.codepen.io/502545/2021-02-08_20-46-41.jpg"
          ></video> */}
          <Player
            controls
            url="https://www.youtube.com/watch?v=RNDCHu46t4I&t=5s"
            pauseVideoHanlder={pauseVideoHanlder}
            playVideoHandler={playVideoHandler}
            playerRef={playerRef}
            isVideoPlaying={isVideoPlaying}
          />

          <div className="play-button-aperture-left"></div>
          <div className="play-button-aperture-top-right"></div>
          <div className="play-button-aperture-bottom-right"></div>
        </div>
      </div>
    </div>
  );
};

export default VideoSection;
