import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sidebarCollapsed: false,
};

export const landingSlice = createSlice({
  name: "landing",
  initialState,
  reducers: {
    setSidebarCollapsed: (state, action) => {
      state.sidebarCollapsed = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSidebarCollapsed } = landingSlice.actions;
export const getSidebarCollapsed = ({ landing }) => landing?.sidebarCollapsed;
export default landingSlice.reducer;
