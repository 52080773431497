import "./CloseIcon.scss";

const CloseIcon = ({ onCloseHandler, className }) => {
  return (
    <div className={`close-icon ${className}`} onClick={onCloseHandler}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="2rem"
        height="2rem"
        viewBox="0 0 24 24"
      >
        <path
          fill="white"
          d="m6.4 18.308l-.708-.708l5.6-5.6l-5.6-5.6l.708-.708l5.6 5.6l5.6-5.6l.708.708l-5.6 5.6l5.6 5.6l-.708.708l-5.6-5.6z"
        />
      </svg>
    </div>
  );
};

export default CloseIcon;
