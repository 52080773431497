import React from "react";
import { motion } from "framer-motion";
import Button from "App/Components/Button";
import { useNavigate } from "react-router-dom";
import {
  titleVariants,
} from "../../motion";
import "../../Carousel.scss";
import EvenSlide from "../EvenSlide";
import OddSlide from "../OddSlide";
const MobileSection = ({ carouselList }) => {
  const navigate = useNavigate();

  const knowMoreHandler = (e) => {
    let link = e.target.dataset["link"];
    navigate(link);
  };
  return (
    <div className="carousel pt-[8.5rem] pb-[11rem] sm:hidden block">
      {carouselList?.map((val, i) => {
        return (
          <div key={val?.id}>
            <div className="flex gap-4 w-full" >
              <div className="flex gap-2  flex-col">
                <motion.h2
                  variants={titleVariants}
                  initial={"initial"}
                  whileInView={"visible"}
                >
                  {val?.title}
                </motion.h2>
                <motion.div
                  className="know-more-wrapper"
                  variants={titleVariants}
                  initial={"initial"}
                  whileInView={"visible"}
                >
                  <Button
                    className={`know-more-btn ${
                      (i + 1) % 2 === 0 ? "red-700-bg" : "purple-100-bg"
                    }`}
                    data-link={val?.urlLink}
                    onClick={knowMoreHandler}
                  >
                    Learn More
                  </Button>
                </motion.div>
              </div>
            </div>
            {/* Render slides dynamically based on currentSlide */}
            {(i + 1) % 2 === 0 ? (
              <EvenSlide key={val?.id}
                data={val}
                currentSlide={true}
                hideKnowMore
                knowMoreHandler={knowMoreHandler}
              />
            ) : (
              <OddSlide key={val?.id}
                data={val}
                hideKnowMore
                currentSlide={true}
                knowMoreHandler={knowMoreHandler}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default MobileSection;
