import slider2Gradient1 from "Assets/Images/home/slider-2-gradient-1.webp";
import slider2Gradient2 from "Assets/Images/home/slider-2-gradient-2.webp";
import { motion } from "framer-motion";
import {
  gradientVariants,
  mobSlideImg1Variants,
  rightKnowMoreBtnVariants,
  slideImg2Variants,
  slideWebImg1Variants,
} from "../../motion";
import Button from "App/Components/Button";
import "./EvenSlide.scss";
import useScreenSize from "App/Hooks/ScreenSize";
import { useMemo } from "react";

const EvenSlide = ({
  currentSlide,
  knowMoreHandler,
  hideKnowMore,
  data,
  className,
}) => {
  const screenSize = useScreenSize()?.width;
  const leftSliderImgVariant = useMemo(() => {
    return screenSize > 768 ? slideWebImg1Variants : mobSlideImg1Variants;
  }, [screenSize]);
  return (
    <div className={`slide  active even-slide ${className}`}>
      <div className={`relative md:left-[-10%] left-0`}>
        <div
          style={{ backgroundImage: `url(${data?.sliderImgOne})` }}
          className="image1 slider-image"
        ></div>
        <div
          style={{ backgroundImage: `url(${slider2Gradient2})` }}
          className="gradient img1"
        ></div>
        <div
          style={{ backgroundImage: `url(${data?.sliderImgTwo})` }}
          className="image2 slider-image"
        ></div>
        <div
          style={{ backgroundImage: `url(${slider2Gradient1})` }}
          className="gradient img2"
        >
          {!hideKnowMore && (
            <motion.div
              className="know-more-wrapper"
            >
              <Button
                className="know-more-btn red-700-bg"
                data-link={data?.urlLink}
                onClick={knowMoreHandler}
              >
                Learn More
              </Button>
            </motion.div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EvenSlide;
