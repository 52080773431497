import discover from "Assets/Images/collectors/discover.png";
import buy from "Assets/Images/collectors/buy.png";
import managecurate from "Assets/Images/collectors/manage-curate.png";
import display from "Assets/Images/collectors/display.png";
import keepuptodate from "Assets/Images/collectors/keep-upto-date.png";

export const blockData = [
  {
    id: 1,
    image: discover,
    details:
      "A whole new universe of digital assets and experiences from creators across the globe.",
    heading: "Discover",
  },
  {
    id: 2,
    image: buy,
    details: "Buying experiences and opportunities uniquely tailored to you.",
    heading: "Buy",
  },
  {
    id: 3,
    image: managecurate,
    details:
      "Organise your portfolio of assets into collections and display lists with simple and intuitive tools.",
    heading: "Manage and Curate",
  },
  {
    id: 4,
    image: display,
    details:
      "Showcase and share your assets anywhere: On your mobile device, on your desktop, on gallery walls or in virtual environments.",
    heading: "Display",
  },
  {
    id: 5,
    image: keepuptodate,
    details: "View posts and events from your favorite creators and never miss a beat.",
    heading: "Keep up-to-date",
  },
];
