import leftGradient1 from "Assets/Images/contact/contact-hero-gradient-1.png"
import leftGradient2 from "Assets/Images/contact/contact-hero-gradient-3.png"
import rightGradient from "Assets/Images/contact/contact-hero-gradient-2.png"
import { motion, useScroll, useTransform } from "framer-motion";
import "./HeroSection.scss";
import { useRef } from "react";

const HeroSection = () => {
  const textRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: textRef,
    offset: ["start start", "end start"],
  });
  const textLeftXScroll = useTransform(scrollYProgress, [1, 0], [-50, 0]);
  const textRightXScroll = useTransform(scrollYProgress, [1, 0], [50, 0]);

  return (
    <div className="contact-hero relative overflow-hidden"  ref={textRef}>
      <img
        className="gradient absolute top-0 left-0"
        src={leftGradient1}
        alt=""
      />     <img
      className="gradient absolute top-0 left-0"
      src={leftGradient2}
      alt=""
    />
      <img
        className="gradient absolute top-0 right-0"
        src={rightGradient}
        alt=""
      />
      <div className="text-wrapper px-4">
        <motion.h1 style={{ x: textLeftXScroll }}>Get in touch</motion.h1>
        <motion.h1 style={{ x: textRightXScroll }}>with us</motion.h1>
        <p>Tell us more about what you've got in mind.</p>
      </div>
    </div>
  );
};
export default HeroSection;
