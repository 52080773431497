import gridBg from "Assets/Images/creators/grid-bg.png";
import gridSectionGradientImage from "Assets/Images/creators/grid-section-gradient.png";
import "./GridBgAndText.scss";
import { useRef } from "react";
import { useScroll, useTransform } from "framer-motion";
import blueMd from "Assets/Images/creators/particles/particle-glass-blue-md.png";
import blueSm from "Assets/Images/creators/particles/particle-glass-blue-sm.png";
import purpleMd from "Assets/Images/creators/particles/particle-glass-purple-md.png";
import purpleSm from "Assets/Images/creators/particles/particle-glass-purple-sm.png";
import redMd from "Assets/Images/creators/particles/particle-glass-red-md.png";
import redSm from "Assets/Images/creators/particles/particle-glass-red-sm.png";
import Particles from "App/Components/Particles";


const particles = [
  { id: 1, src: blueMd ,size:"md"},
  { id: 2, src: purpleSm ,size:"sm"},
  { id: 3, src: redMd ,size:"md"},
  { id: 4, src: blueSm ,size:"sm"},
  { id: 5, src: purpleMd,size:"md" },
  { id: 6, src: redSm,size:"sm" },
  { id: 7, src: blueMd,size:"md" },
];
const GridBgAndText = () => {
  const bgRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: bgRef,
    offset: ["start end", "end start"],
  });
  const particleYscroll = useTransform(
    scrollYProgress,
    [0,  1],
    ["0%","50%"]
  );
  return (
    <div className="grid-bg-and-text relative" ref={bgRef}>
      <Particles particles={particles} particleYscroll={particleYscroll}/>
      <img className="gradient-left md:inline-block hidden" src={gridSectionGradientImage} alt=""/>
      <img className="bg-image" src={gridBg} alt="" />
      <div className="text-wrapper flex flex-col gap-[2.6rem] max-w-[72rem] w-full m-auto">
      <div className="max-w-[47.6rem] mr-auto w-full">
      <h5>Showcase your work in the</h5>
      <h1>most beautiful and compelling way</h1>
      </div>
      <p >
        Elevate your artwork with Cur8's cutting-edge display solutions. With
        customizable frames, walls, display lists and 3D environments, Cur8
        enhances the presentation of your work through an elegantly designed,
        easy-to-use platform.
      </p>
      </div>
    </div>
  );
};

export default GridBgAndText;
