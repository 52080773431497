import { useLocation, useNavigate } from "react-router-dom";
import logo from "Assets/Images/nav-logo.svg";
import CloseIcon from "App/Components/CloseIcon";

export const NavbarContent = ({ setMenuCollapse }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const homeNavHandler = () => {
    navigate("/");
    onCloseHandler();
  };

  const tabs = [
    { id: 1, text: "Collectors", link: "/collectors" },
    { id: 2, text: "Creators", link: "/creators" },
    { id: 3, text: "About", link: "/about" },
    { id: 4, text: "Contact", link: "/contact" },
  ];
  const onNavigateHandler = (link) => {
    navigate(link);
    onCloseHandler();
  };
  const onCloseHandler = () => {
    setMenuCollapse && setMenuCollapse(false);
  };
  return (
    <div
      className={`scroll-menu-div flex w-full items-center h-full justify-between`}
    >
      <div className="flex justify-between w-full items-center">
        <div className="logo-wrapper">
          <img loading="lazy"
            className={`cursor-pointer`}
            onClick={homeNavHandler}
            alt="No Img"
            src={logo}
          />
        </div>
        <CloseIcon className="md:hidden flex" onCloseHandler={onCloseHandler} />
      </div>

      <div className="navbar-tabs flex align-center gap-[2rem]">
        <div className="flex gap-[1.875rem] items-center tabs-wrapper">
          {tabs?.map((el, i) => (
            <div
              key={el?.id}
              onClick={() => onNavigateHandler(el?.link)}
              className={`tab-link ${pathname === el?.link ? "active":"inactive"}`}
            >
              {el?.text}
            </div>
          ))}
          <a
            href={`${process.env.REACT_APP_USER_URL}`}
            target="_blank"
            rel="noreferrer"
            className="mixed-gradient-btn"
          >
            Launch App
          </a>
        </div>
      </div>
    </div>
  );
};

export default NavbarContent;
