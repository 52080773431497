import { useEffect } from "react";
import HeroSection from "./Components/HeroSection";
import ImageBannerAndTextBox from "./Components/ImageBannerAndTextBox";
import GridBgAndText from "./Components/GridBgAndText";
import GradientBackgroundSection from "./Components/GradientBackgroundSection";
import CardsSection from "./Components/CardsSection";
// import CardCarousel from "./Components/CardCarousel";


const Creators = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => window.scrollTo(0, 0);
  }, []);

  return (
    <div className="creators flex flex-col">
        <HeroSection />
       <div className="md:mt-[15rem] mt-[4.5rem]">
       <ImageBannerAndTextBox/>
       </div>
       <GridBgAndText/>
       <GradientBackgroundSection/>
       <CardsSection/>
       {/* <CardCarousel/> */}
    </div>
  );
};

export default Creators;
