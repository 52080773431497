import "./Card.scss";
import purpleRightBar from "Assets/Images/creators/purple-right-card-bar.png";
import purpleLeftBar from "Assets/Images/creators/purple-left-card-bar.png";
import redRightBar from "Assets/Images/creators/red-right-card-bar.png";
import redLeftBar from "Assets/Images/creators/red-left-card-bar.png";
import blueRightBar from "Assets/Images/creators/blue-right-card-bar.png";
import blueLeftBar from "Assets/Images/creators/blue-left-card-bar.png";
const Card = ({ cardData,className }) => {
  return (
    <div className={`card relative flex flex-col gap-4 ${className}`}>
      <img
        src={
          cardData?.color === "purple"
            ? purpleRightBar
            : cardData?.color === "red"
            ? redRightBar
            : blueRightBar
        }
        className={`bar left-bar`}
        alt=""
      />
      <img className="card-image" src={cardData?.image} alt="" />
      <h3>{cardData?.title}</h3>
      <img
        src={
          cardData?.color === "purple"
            ? purpleLeftBar
            : cardData?.color === "red"
            ? redLeftBar
            : blueLeftBar
        }
        className={`bar right-bar`}
        alt=""
      />
    </div>
  );
};

export default Card;
