import "./ImageDetailBlock.scss";
import purpleGlassBox from "Assets/Images/collectors/purple-glass-box.png";
import blueGlassBox from "Assets/Images/collectors/blue-glass-box.png";
import redGlassBox from "Assets/Images/collectors/red-glass-box.png";
import { motion, useScroll, useTransform } from "framer-motion";
import { useMemo, useRef } from "react";
import { OddOrEven } from "App/Helper/utilities";
import useScreenSize from "App/Hooks/ScreenSize";

const ImageDetailBlock = ({ data, index }) => {
  const screenSize = useScreenSize().width;
  let glassBoxSrc = useMemo(() => {
    switch (index) {
      case 1:
      case 4:
        return purpleGlassBox;
      case 3:
      case 5:
        return redGlassBox;
      case 2:
        return blueGlassBox;
      default:
        return purpleGlassBox;
    }
  }, [index]);
  const cardRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: cardRef,
    offset: ["start end", "end start"],
  });
  const textWebYScroll = useTransform(
    scrollYProgress,
    [0, 0.4, 1],
    ["0%", "0%", "-50%"]
  );
  const textMobYScroll = useTransform(scrollYProgress, [0, 0.4, 1], [0, 0, 0]);
  const textYScroll = screenSize > 768 ? textWebYScroll : textMobYScroll;
  const textGlassBoxYScroll = useTransform(
    scrollYProgress,
    [1, 0.5, 0],
    [200, 0, 0]
  );
  const textGlassBoxWebXScroll = useTransform(
    scrollYProgress,
    [0, 0.5, 1],
    [-300, 0, 0]
  );
  const leftImageGlassWebBoxXScroll = useTransform(
    scrollYProgress,
    [1, 0.5, 0],
    [250, 0, 0]
  );
  const rightImageGlassBoxWebXScroll = useTransform(
    scrollYProgress,
    [1, 0.5, 0],
    [-250, 0, 0]
  );
  const textGlassBoxMobXScroll = useTransform(
    scrollYProgress,
    [0, 0.5, 1],
    [-175, 0, 0]
  );
  const leftImageGlassBoxMobXScroll = useTransform(
    scrollYProgress,
    [1, 0.5, 0],
    [175, 0, 0]
  );
  const rightImageGlassBoxMobXScroll = useTransform(
    scrollYProgress,
    [1, 0.5, 0],
    [-175, 0, 0]
  );
  const textGlassBoxXScroll = screenSize > 768 ? textGlassBoxWebXScroll : textGlassBoxMobXScroll;
  const leftImageGlassBoxXScroll = screenSize > 768 ? leftImageGlassWebBoxXScroll : leftImageGlassBoxMobXScroll;
  const rightImageGlassBoxXScroll = screenSize > 768 ? rightImageGlassBoxWebXScroll : rightImageGlassBoxMobXScroll;
  return (
    <div className="block-wrapper flex w-full gap-4 relative" ref={cardRef}>
      {OddOrEven(index) === "odd" && (
        <motion.img
          style={{ x: textGlassBoxXScroll }}
          className="glass-box absolute md:hidden block mobile-block-glass-box"
          src={blueGlassBox}
          loading="lazy"
          alt=""
        />
      )}
      <div className="md:w-[50%] w-full flex md:justify-end justify-center image-main-wrapper">
        <motion.div className="image-box">
          <motion.img
            src={data?.image}
            className="block-img"
            loading="lazy"
            alt=""
          />
          <motion.img
            style={{
              x:
                OddOrEven(index) === "odd"
                  ? leftImageGlassBoxXScroll
                  : rightImageGlassBoxXScroll,
            }}
            className="glass-box"
            src={glassBoxSrc}
            loading="lazy"
            alt=""
          />
        </motion.div>
      </div>
      <div className="text-wrapper relative">
        <motion.div
          style={{ y: textYScroll }}
          className="flex flex-col items-start md:justify-end justify-center h-full gap-[1.25rem] heading-desc-wrapper"
        >
          <h2>{data?.heading}</h2>
          <p className="text-left">{data?.details}</p>
        </motion.div>
        <motion.img
          style={{ y: textGlassBoxYScroll }}
          className="glass-box absolute md:block hidden"
          src={blueGlassBox}
          loading="lazy"
          alt=""
        />
      </div>
    </div>
  );
};
export default ImageDetailBlock;
