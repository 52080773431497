import homeGradientNine from "Assets/Images/home/home-9.webp";
import homeGradientTen from "Assets/Images/home/home-10.webp";
import homeGradientEleven from "Assets/Images/home/home-11.webp";
import { motion, useScroll, useTransform } from "framer-motion";
import "./HowItWorksSection.scss";
import { MobBarVariants } from "./motion/index.js";
import { useRef } from "react";

const HowItWorksSection = ({ className, title, description, imageSrc }) => {
  const textRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: textRef,
    offset: ["start end", "end start"],
  });
  const textXScroll = useTransform(scrollYProgress, [0, 0.4, 1], [-50, 0, 0]);
  const textBarYScroll = useTransform(
    scrollYProgress,
    [1, 0.5, 0],
    [40, 0, 0]
  );
  const imgRef = useRef(null);
  const imageScrollProgress = useScroll({
    target: imgRef,
    offset: ["start end", "end start"],
  });
  const imageXScroll = useTransform(
    imageScrollProgress.scrollYProgress,
    [0, 0.4, 1],
    [50, 0, 0]
  );
  const imageBarYScroll = useTransform(
    imageScrollProgress.scrollYProgress,
    [1, 0.5, 0.1, 0],
    [-50, 0, 0, 0]
  );
  return (
    <div
      className={`how-it-work md:mx-[5rem] mt-0 md:mt-[17dvw] mx-[2rem] flex flex-col relative ${className}`}
    >
      <div
        style={{ backgroundImage: `url(${homeGradientNine})` }}
        className="absolute gradient-nine"
        alt=""
      />
      <motion.div
        ref={textRef}
        className={`text-wrapper flex flex-col gap-[2rem] relative`}
        style={{ x: textXScroll }}
      >
        <motion.img
          className="absolute right-bar bar-gradient md:flex hidden"
          src={homeGradientTen}
          style={{ y: textBarYScroll }}
          alt=""
        />
        <motion.img
          className="absolute right-bar bar-gradient md:hidden flex"
          src={homeGradientEleven}
          variants={MobBarVariants}
          initial="initial"
          whileInView="visible"
          alt=""
        />
        <motion.img
          className="absolute left-bar bar-gradient md:hidden flex"
          src={homeGradientEleven}
          variants={MobBarVariants}
          initial="initial"
          whileInView="visible"
          alt=""
        />
        <h1>{title}</h1>
        <p>{description}</p>
      </motion.div>
      <motion.div
        ref={imgRef}
        className="img-wrapper relative"
        style={{ x: imageXScroll }}
      >
        <motion.img
          className="absolute left-bar md:flex hidden"
          src={homeGradientEleven}
          style={{ y: imageBarYScroll }}
          alt=""
        />
        <img loading="lazy" className="screenshot-img" src={imageSrc} alt="" />
      </motion.div>
    </div>
  );
};
export default HowItWorksSection;
